<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <br><br><br><br>
        <v-img
            :src="require('../assets/CA_1_All_fill_registered-BIG.png')"
            class="my-3"
            contain
            height="500"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          CCRS Reports Generator
        </h1>

        <p class="subheading font-weight-regular">
          &copy; {{ copyYear }} Confidence Analytics
          <br>
          <a href="mailto://info@conflabs.com">info@conflabs.com</a>
          <br>
          <a href="https://conflabs.com" target="_blank">https://conflabs.com</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
export default {
  name: 'Home',
  mounted() {
    this.$auth.onAuthStateChanged((user) => {
      if (user) {
        this.$router.push({path: '/dashboard'});
      }
    });
    this.getCurrentYear();
  },
  data () {
    return {
      copyYear: '',
    }
  },
  methods: {
    login() {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      signInWithPopup(this.$auth, provider).then(res => {
        this.$store.state.user.id = res.user.uid;
        this.$store.state.user.name = res.user.displayName;
        this.$store.state.user.email = res.user.email;
        this.$store.state.user.photo = res.user.photoURL;
      }).catch(error => {alert(error.message)});
    },
    getCurrentYear () {
      this.copyYear = new Date().getFullYear();
    }
  },
}
</script>
