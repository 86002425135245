<template>
  <v-app>
    <v-app-bar
      app
      color="#0D6000"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="@/assets/CA_1_All_fill_registered.png"
          transition="scale-transition"
          width="40"
        />
        <h1>CCRS Reports v2.0.0</h1>
      </div>

      <v-spacer></v-spacer>

      <v-btn v-if="$store.state.user.id" @click="$auth.signOut()">
        {{$store.state.user.name}}
      </v-btn>
      <v-btn @click="login" light v-else>Log In</v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";

export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted() {
    this.$auth.onAuthStateChanged((user) => {
      if (user) {
        this.$store.state.user.id = user.uid;
        this.$store.state.user.name = user.displayName;
        this.$store.state.user.email = user.email;
        this.$store.state.user.photo = user.photoURL;
      } else {
        this.$store.state.user.id = null;
        this.$store.state.user.name = null;
        this.$store.state.user.email = null;
        this.$store.state.user.photo = null;
      }
    });
  },
  methods: {
    login() {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      signInWithPopup(this.$auth, provider).then(res => {
        this.$store.state.user.id = res.user.uid;
        this.$store.state.user.name = res.user.displayName;
        this.$store.state.user.email = res.user.email;
        this.$store.state.user.photo = res.user.photoURL;
      }).catch(error => {alert(error.message)});
    }
  }
};
</script>
