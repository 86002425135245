import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {db,auth} from '@/firebase-config/firebase.js'

Vue.config.productionTip = false

router.beforeEach((to,from,next) => {
  if (to.matched.some((route) => {return route.meta.requiresAuth;})){
    auth.onAuthStateChanged((user) => {
      if (user) {
        next();
      } else {
        next({path: "/"});
      }
    });
  } else {
   next();
  }
});

Vue.prototype.$db = db;
Vue.prototype.$auth = auth;
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
