import {initializeApp} from "firebase/app";
import {connectFirestoreEmulator,getFirestore} from "firebase/firestore";
// import {connectAuthEmulator,getAuth} from "firebase/auth";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDPzMB2fNWkhpnEISKPyJKXk5-f--qYe70",
    authDomain: "ccrs-6c34b.firebaseapp.com",
    projectId: "ccrs-6c34b",
    storageBucket: "ccrs-6c34b.appspot.com",
    messagingSenderId: "875596298123",
    appId: "1:875596298123:web:f7d3bc513a33d693b1e18e"
};

const App = initializeApp(firebaseConfig);
export const db = getFirestore(App);
export const auth = getAuth(App);

if (location.hostname === "localhost") {
    connectFirestoreEmulator(db,"localhost", 8082);
    // connectAuthEmulator(auth, "localhost:9099");
}